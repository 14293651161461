import React from 'react';
import '../styles/PhotoGalleryPage.css'; // Make sure to create this CSS file in your styles directory

function PhotoGalleryPage({ photos }) {
  return (
    <div className="photo-gallery">
      {photos.map((photo, index) => (
        <div key={index} className="photo-box">
          <img src={photo.url} alt={photo.alt || 'Photo'} />
        </div>
      ))}
    </div>
  );
}

export default PhotoGalleryPage;






import prof from '../assets/prof.jpg';

import soumyaProf from '../assets/profs/soumyaProf.jpeg';
import sunnyProf from '../assets/profs/sunnyProf.jpeg';
import marinaProf from '../assets/profs/marinaProf.jpeg';
import payamProf from '../assets/profs/payamProf.jpeg';
import jennaProf from '../assets/profs/jennaProf.jpeg';
import jonathanProf from '../assets/profs/jonathanProf.jpeg';
import artProf from '../assets/profs/artProf.jpeg';
import elyseProf from '../assets/profs/elyseProf.png';
import adamProf from '../assets/profs/adamProf.jpeg';
import schiaviProf from '../assets/profs/schiaviProf.jpeg';
import millerProf from '../assets/profs/millerProf.jpeg';
import mirandaProf from '../assets/profs/mirandaProf.jpeg';



// Imports for vectorcam
import vectorcam1 from '../assets/vectorcam/vectorcam1.jpeg';
import vectorcam2 from '../assets/vectorcam/vectorcam2.jpeg';
import vectorcam3 from '../assets/vectorcam/vectorcam3.jpeg';
import vectorcam4 from '../assets/vectorcam/vectorcam4.jpeg';

// Imports for maputo
import maputo1 from '../assets/maputo/maputo1.jpeg';
import maputo2 from '../assets/maputo/maputo2.jpeg';
import maputo3 from '../assets/maputo/maputo3.jpeg';
import maputo4 from '../assets/maputo/maputo4.jpeg';
import maputo5 from '../assets/maputo/maputo5.jpeg';

// Imports for quilemane
import quilemane1 from '../assets/quilemane/quilemane1.jpeg';
import quilemane2 from '../assets/quilemane/quilemane2.jpeg';
import quilemane3 from '../assets/quilemane/quilemane3.jpeg';
import quilemane4 from '../assets/quilemane/quilemane4.jpeg';

// Imports for field
import field1 from '../assets/field/field1.jpeg';
import field2 from '../assets/field/field2.jpeg';
import field3 from '../assets/field/field3.jpeg';
import field4 from '../assets/field/field4.jpeg';
import field5 from '../assets/field/field5.jpeg';

// Imports for quilemaneMaputo
import quilemaneMaputo1 from '../assets/quilemaneMaputo/quilemaneMaputo1.jpeg';
import quilemaneMaputo2 from '../assets/quilemaneMaputo/quilemaneMaputo2.jpeg';
import quilemaneMaputo3 from '../assets/quilemaneMaputo/quilemaneMaputo3.jpeg';
import quilemaneMaputo4 from '../assets/quilemaneMaputo/quilemaneMaputo4.jpeg';

// Imports for esurgi
import esurgi1 from '../assets/esurgi/esurgi1.jpeg';
import esurgi2 from '../assets/esurgi/esurgi2.jpeg';
import esurgi3 from '../assets/esurgi/esurgi3.jpeg';
import esurgi4 from '../assets/esurgi/esurgi4.jpeg';
import esurgi5 from '../assets/esurgi/esurgi5.jpeg';

// Imports for research
import research1 from '../assets/research/research1.jpeg';
import research2 from '../assets/research/research2.jpeg';

// Imports for securisyn
import securisyn1 from '../assets/securisyn/securisyn1.jpeg';
import securisyn2 from '../assets/securisyn/securisyn2.jpeg';

// Imports for epipocket
import epipocket1 from '../assets/epipocket/epipocket1.jpeg';
import epipocket2 from '../assets/epipocket/epipocket2.jpeg';
import epipocket3 from '../assets/epipocket/epipocket3.jpeg';
import epipocket4 from '../assets/epipocket/epipocket4.jpeg';

// Imports for hackathon
import hackathon1 from '../assets/hackathon/hackathon1.jpeg';
import hackathon2 from '../assets/hackathon/hackathon2.jpeg';
import hackathon3 from '../assets/hackathon/hackathon3.jpeg';

// Imports for hackathonScreenshots
import hackathonScreenshots1 from '../assets/hackathonScreenshots/hackathonScreenshots1.jpeg';
import hackathonScreenshots2 from '../assets/hackathonScreenshots/hackathonScreenshots2.jpeg';
import hackathonScreenshots3 from '../assets/hackathonScreenshots/hackathonScreenshots3.jpeg';
import hackathonScreenshots4 from '../assets/hackathonScreenshots/hackathonScreenshots4.jpeg';

// Imports for appdev
import appdev1 from '../assets/appdev/appdev1.jpeg';
import appdev2 from '../assets/appdev/appdev2.jpeg';
import appdev3 from '../assets/appdev/appdev3.jpeg';
import appdev4 from '../assets/appdev/appdev4.jpeg';
import appdev5 from '../assets/appdev/appdev5.jpeg';

// Imports for cad1
import cad11 from '../assets/cad1/cad11.jpeg';
import cad12 from '../assets/cad1/cad12.jpeg';
import cad13 from '../assets/cad1/cad13.jpeg';

// Imports for cad2
import cad21 from '../assets/cad2/cad21.jpeg';
import cad22 from '../assets/cad2/cad22.jpeg';
import cad23 from '../assets/cad2/cad23.jpeg';
import cad24 from '../assets/cad2/cad24.jpeg';
import cad25 from '../assets/cad2/cad25.jpeg';

// Imports for cad3
import cad31 from '../assets/cad3/cad31.jpeg';
import cad32 from '../assets/cad3/cad32.jpeg';
import cad33 from '../assets/cad3/cad33.jpeg';

// Imports for designTeam
import designTeam1 from '../assets/designTeam/designTeam1.jpeg';
import designTeam2 from '../assets/designTeam/designTeam2.jpeg';
import designTeam3 from '../assets/designTeam/designTeam3.jpeg';

// Imports for designTeamShadow
import designTeamShadow1 from '../assets/designTeamShadow/designTeamShadow1.jpeg';
import designTeamShadow2 from '../assets/designTeamShadow/designTeamShadow2.jpeg';
// import designTeamShadow3 from '../assets/designTeamShadow/designTeamShadow3.jpeg';

// Imports for designTeamSimulation
import designTeamSimulation1 from '../assets/designTeamSimulation/designTeamSimulation1.jpeg';
import designTeamSimulation2 from '../assets/designTeamSimulation/designTeamSimulation2.jpeg';





const photos1 = [
  // Professors
  { url: prof, alt: 'Professor' },
//   { url: soumyaProf, alt: 'Soumya' },
//   { url: sunnyProf, alt: 'Sunny' },
//   { url: marinaProf, alt: 'Marina' },
  // Add more professor images here...

  // Vectorcam
  { url: vectorcam1, alt: 'Vectorcam 1' },
  { url: vectorcam2, alt: 'Vectorcam 2' },
  { url: vectorcam3, alt: 'Vectorcam 3' },
  { url: vectorcam4, alt: 'Vectorcam 4' },
  // Add more vectorcam images here...

  // Maputo
  { url: maputo1, alt: 'Maputo 1' },
  { url: maputo2, alt: 'Maputo 2' },
  { url: maputo3, alt: 'Maputo 3' },
  { url: maputo4, alt: 'Maputo 4' },
  { url: maputo5, alt: 'Maputo 5' },
  // Add more Maputo images here...

  // Quilemane
  { url: quilemane1, alt: 'Quilemane 1' },
  { url: quilemane2, alt: 'Quilemane 2' },
  { url: quilemane3, alt: 'Quilemane 3' },
  { url: quilemane4, alt: 'Quilemane 4' },
  // Add more Quilemane images here...

  // Field
  { url: field1, alt: 'Field 1' },
  { url: field2, alt: 'Field 2' },
  { url: field3, alt: 'Field 3' },
  { url: field4, alt: 'Field 4' },
  { url: field5, alt: 'Field 5' },
  // Add more Field images here...

  // QuilemaneMaputo
  { url: quilemaneMaputo1, alt: 'QuilemaneMaputo 1' },
  { url: quilemaneMaputo2, alt: 'QuilemaneMaputo 2' },
  { url: quilemaneMaputo3, alt: 'QuilemaneMaputo 3' },
  { url: quilemaneMaputo4, alt: 'QuilemaneMaputo 4' },
  // Add more QuilemaneMaputo images here...

  // Esurgi
  { url: esurgi1, alt: 'Esurgi 1' },
  { url: esurgi2, alt: 'Esurgi 2' },
  { url: esurgi3, alt: 'Esurgi 3' },
  { url: esurgi4, alt: 'Esurgi 4' },
  { url: esurgi5, alt: 'Esurgi 5' },
  // Add more Esurgi images here...

  // Research
  { url: research1, alt: 'Research 1' },
  { url: research2, alt: 'Research 2' },
  // Add more Research images here...

  // Securisyn
  { url: securisyn1, alt: 'Securisyn 1' },
  { url: securisyn2, alt: 'Securisyn 2' },
  // Add more Securisyn images here...

  // Epipocket
  { url: epipocket1, alt: 'Epipocket 1' },
  { url: epipocket2, alt: 'Epipocket 2' },
  { url: epipocket3, alt: 'Epipocket 3' },
  { url: epipocket4, alt: 'Epipocket 4' },
  // Add more Epipocket images here...

  // Hackathon
  { url: hackathon1, alt: 'Hackathon 1' },
  { url: hackathon2, alt: 'Hackathon 2' },
  { url: hackathon3, alt: 'Hackathon 3' },
  // Add more Hackathon images here...

  // HackathonScreenshots
  { url: hackathonScreenshots1, alt: 'Hackathon Screenshot 1'},
  { url: hackathonScreenshots2, alt: 'Hackathon Screenshot 2' },
  { url: hackathonScreenshots3, alt: 'Hackathon Screenshot 3' },
  { url: hackathonScreenshots4, alt: 'Hackathon Screenshot 4' },
  // Add more HackathonScreenshots images here...
  
  // Appdev
  { url: appdev1, alt: 'App Development 1' },
  { url: appdev2, alt: 'App Development 2' },
  { url: appdev3, alt: 'App Development 3' },
  { url: appdev4, alt: 'App Development 4' },
  { url: appdev5, alt: 'App Development 5' },
  // Add more Appdev images here...
  
  // Cad1
  { url: cad11, alt: 'CAD Design 1-1' },
  { url: cad12, alt: 'CAD Design 1-2' },
  { url: cad13, alt: 'CAD Design 1-3' },
  // Add more Cad1 images here...
  
  // Cad2
  { url: cad21, alt: 'CAD Design 2-1' },
  { url: cad22, alt: 'CAD Design 2-2' },
  { url: cad23, alt: 'CAD Design 2-3' },
  { url: cad24, alt: 'CAD Design 2-4' },
  { url: cad25, alt: 'CAD Design 2-5' },
  // Add more Cad2 images here...
  
  // Cad3
  { url: cad31, alt: 'CAD Design 3-1' },
  { url: cad32, alt: 'CAD Design 3-2' },
  { url: cad33, alt: 'CAD Design 3-3' },
  // Add more Cad3 images here...
  
  // DesignTeam
  { url: designTeam1, alt: 'Design Team 1' },
  { url: designTeam2, alt: 'Design Team 2' },
  { url: designTeam3, alt: 'Design Team 3' },
  // Add more DesignTeam images here...
  
  // DesignTeamShadow
  { url: designTeamShadow1, alt: 'Design Team Shadow 1' },
  { url: designTeamShadow2, alt: 'Design Team Shadow 2' },
  // Add more DesignTeamShadow images here...
  
  // DesignTeamSimulation
  { url: designTeamSimulation1, alt: 'Design Team Simulation 1' },
  { url: designTeamSimulation2, alt: 'Design Team Simulation 2' },
  // Add more DesignTeamSimulation images here...
  ];


const photos = photos1.sort(() => Math.random() - 0.5)

export default photos
import React from 'react';
import SimpleImageSlider from "react-simple-image-slider"; // Ensure this package is installed
import "../styles/ProjectsGallery.css"; // Ensure the CSS file is correctly imported

function ProjectsGallery({ projects }) {
  return (
    <div className="projects-gallery">
      {projects.map((project, index) => (
        <div key={index} className="project-box">
          <SimpleImageSlider
            width={460}
            height={315}
            images={project.images}
            showBullets={false}
            showNavs={true}
          />
          <div className="project-info">
            <div className="caption">
              <h3>{project.title}</h3>
              <p>{project.description}</p>
            </div>
            <a href={project.link} className="learn-more-link">
              <button className="learn-more-btn">
                Learn More →
              </button>
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProjectsGallery;


import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";

function Experience() {
  return (
    <div className="experience">
      <VerticalTimeline lineColor="#3e497a">
      <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2016 - 2020"
          iconStyle={{ background: "#3e497a", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            J.K. Mullen High School, Denver, CO
          </h3>

          <h4 className="vertical-timeline-element-subtitle">
            High School Diploma
          </h4>

          <p> </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2018 - 2020"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Engineering Intern - Securisyn Medical
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Denver, CO
          </h4>
          <p>Assisted in the prototyping of their new product, SolidAIRity®.</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2016 - Present"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Founder of Startup Company - EpiPocket
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Denver, CO
          </h4>
          <p>Designed and patented two silicone carrying cases for the EpiPen® and AUVI-Q® </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2020 - 2024"
          iconStyle={{ background: "#3e497a", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Johns Hopkins University, Baltimore, MD
          </h3>

          <h4 className="vertical-timeline-element-subtitle">
            Bachelor's Degree
          </h4>

          <p> Biomedical Engineering, Applied Mathematics and Statistics Minor</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Spring 2022"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            CAD Designer - JHU Research Team
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Baltimore, Maryland
          </h4>
          <p>Designed casing for device that used oxygen concentrator air-flow data to prevent and predict strokes and heart attacks.</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Summer 2022"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Biomedical Engineering Intern - Esurgi
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Baltimore, Maryland
          </h4>
          <p>
            Solely developed Firebase-backed iOS app to pair with their biomedical device, the Biostabilizer®.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}

export default Experience;

import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/NavigationSlider.css';

function NavigationSlider({ links }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const tabsRef = useRef(new Array(links.length));
  const [highlightStyle, setHighlightStyle] = useState({});

  const updateHighlight = (index) => {
    const tab = tabsRef.current[index];
    setHighlightStyle({
      left: `${tab.offsetLeft}px`,
      width: `${tab.offsetWidth}px`,
      opacity: 1, // Add opacity to make the highlight visible
    });
  };

  // Effect to set the initial highlight
  useEffect(() => {
    updateHighlight(0);
  }, []);

  const handleClick = (index, path) => {
    setActiveIndex(index);
    navigate(path);
    updateHighlight(index);
  };

  return (
    <div className="navigation-slider">
        <div className="tabs">
          {links.map((link, index) => (
            <button
              key={index}
              ref={(el) => tabsRef.current[index] = el}
              className={`tab ${activeIndex === index ? 'active' : ''}`}
              onClick={() => handleClick(index, link.path)}
            >
              {link.text}
            </button>
          ))}
        </div>
      <div className="highlight" style={highlightStyle} />
    </div>
  );
}

export default NavigationSlider;

import React from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import GithubIcon from "@material-ui/icons/GitHub";
import "../styles/Home.css";

function ContactPage() {
  return (
    <div className="home">
        <div className = "spacer">
        </div>
        <h1> Thanks for visiting my website.</h1>
        <p className="prompt">If you would like to get in contact with me, please reach out via email below. I look forward to hearing from you!</p>
        <div className="icons">
          <a href="https://www.linkedin.com/in/kyle-cooper-07536b230/" target="_blank" rel="noopener noreferrer">
          <LinkedInIcon />
          </a>
          <a href="mailto:kyle.cooper7@outlook.com">
          <EmailIcon />
          </a>
          <a href="https://github.com/kylecooper7" target="_blank" rel="noopener noreferrer">
          <GithubIcon />
          </a>
        </div>
    </div>
  );
}

export default ContactPage;

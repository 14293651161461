import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Experience from "./pages/Experience";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ProjectDisplay from "./pages/ProjectDisplay";
import HomePage from "./pages/HomePage";
import AboutMePage from "./pages/AboutMePage";
import PortfolioPage from "./pages/PortfolioPage";
import OtherContentPage from "./pages/OtherContentPage";
import ContactPage from "./pages/ContactPage";
import PhotoGalleryPage from "./pages/PhotoGalleryPage";



import photos from "./pages/PhotoGalleryContact";






function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        
        <Routes>
          <Route path="/*" element={<HomePage />} />
          <Route path="/aboutMe/*" element={<AboutMePage />} />
          <Route path="/portfolio/*" element={<PortfolioPage />} />
          <Route path="/otherContent/*" element={<OtherContentPage />} />
          <Route path="/contact/*" element={<ContactPage />} />
          <Route path="/photoGallery/*" element={<PhotoGalleryPage photos={photos} />} />
         
          
        </Routes>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default App;

import React from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import GithubIcon from "@material-ui/icons/GitHub";
import "../styles/OtherContentPage.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";



function ThoughtsAndIdeasContent() {
  return (
    <>
      <h2>Thoughts and Ideas</h2>
      <p>This will be a page for me to share many of my thoughts and ideas!</p>
    </>
  );
}

function PhotoGalleryContent() {
  return (
    <>
      <h2>Photo Gallery</h2>
      <p>Here I am going to put a photo gallery!</p>
    </>
  );
}


function OtherContentPage() {


  return (
    <div className="otherContent">
      <div className="otherContent-sidebar">
        <ul>
          <li><Link to={``}>Thoughts and Ideas</Link></li>
          <li><Link to={`photogallery`}>Photo Gallery</Link></li>
        
        </ul>
      </div>
      <div className="otherContent-content">
      <Routes>
        <Route path='/' element = {<ThoughtsAndIdeasContent/>} >
        </Route>
        <Route path={'/photoGallery'} element = {<PhotoGalleryContent />} >
          {/* <PersonalityContent /> */}
        </Route>
       
        
        </Routes>
      </div>
    </div>
  );
}


// function OtherContentPage() {
//   return (
//     <div className="home">
//       <div className="otherContent">
//         <h2> Hi, My Name is Kyle Cooper</h2>
//         <div className="prompt">
//           <p>A biomedical engineer with a passion for problem solving.
//           </p>
//           <p>(Website still under development)
//           </p>
//           <LinkedInIcon />
//           <EmailIcon />
//           <GithubIcon />
//         </div>
//       </div>
     
//     </div>
//   );
// }

export default OtherContentPage;

import React from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import GithubIcon from "@material-ui/icons/GitHub";
import "../styles/AboutMePage.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";



function IntroductionContent() {
  return (
    <>
      <h2>Introduction</h2>
      <p>Hi! I'm Kyle, and I am a college senior studying biomedical engineering at Johns Hopkins University. I get really excited by creating solutions to problems, such as designing medical devices in CAD or developing machine learning powered mobile applications. In my free time, I enjoy going for hikes, volunteering, exercising, skiing, and playing/watching soccer. I am always interested in applying my engineering skills, and am looking forward to the challenges that come next!</p>
    </>
  );
}

function PersonalityContent() {
  return (
    <>
      <h2>Personality</h2>
      <p>Here, I'll add some information about my personality.</p>
    </>
  );
}

function AcademicsContent() {
  return (
    <>
      <h2>Academics</h2>
      <p>Here, I'll add some information about my academic background.</p>
    </>
  );
}

function PersonalEducationContent() {
  return (
    <>
      <h2>Personal Education</h2>
      <p>Here, I'll add some information about my personal education and interests.</p>
    </>
  );
}

function ActivitiesContent() {
  return (
    <>
      <h2>Activities</h2>
      <p>Here, I'll add some information about my extracurricular activities.</p>
    </>
  );
}

function ResumeContent() {
  return (
    <>
      <h2>Resume</h2>
      <p>Here, I'll add a copy of my resume.</p>
    </>
  );
}

function SocialsContent() {
  return (
    <>
      <h2>Socials</h2>
      <p>Here, I'll add some information about my social media profiles.</p>
    </>
  );
}

function AboutMePage() {


  return (
    <div className="about">
      <div className="about-sidebar">
        <ul>
          <li><Link to={``}>Introduction</Link></li>
          <li><Link to={`personality`}>Personality</Link></li>
          <li><Link to={`academics`}>Academics</Link></li>
          <li><Link to={`personal-education`}>Personal Education</Link></li>
          <li><Link to={`activities`}>Activities</Link></li>
          <li><Link to={`resume`}>Resume</Link></li>
          <li><Link to={`socials`}>Socials</Link></li>
        </ul>
      </div>
      <div className="about-content">
      <Routes>
        <Route path='/' element = {<IntroductionContent/>} >
        </Route>
        <Route path={'/personality'} element = {<PersonalityContent />} >
          {/* <PersonalityContent /> */}
          
        </Route>
        <Route path={`/academics`} element = {<AcademicsContent />} >
          {/* <AcademicsContent /> */}
          
        </Route>
        <Route path={`/personal-education`} element = {<PersonalEducationContent />} >
          {/* <PersonalEducationContent /> */}
          
        </Route>
        <Route path={`/activities`} element = {<ActivitiesContent />} >
          {/* <ActivitiesContent /> */}
          
        </Route>
        <Route path={`/resume`} element = {<ResumeContent />} >
          {/* <ResumeContent /> */}
          
        </Route>
        <Route path={`/socials`} element = {<SocialsContent />} >
          {/* <SocialsContent /> */}
          balls
        </Route>
        </Routes>
      </div>
    </div>
  );
}


// function AboutMePage() {
//   return (
//     <div className="home">
//       <div className="about">
//         <h2> Hi, My Name is Kyle Cooper</h2>
//         <div className="prompt">
//           <p>A biomedical engineer with a passion for problem solving.
//           </p>
//           <p>(Website still under development)
//           </p>
//           <LinkedInIcon />
//           <EmailIcon />
//           <GithubIcon />
//         </div>
//       </div>
     
//     </div>
//   );
// }

export default AboutMePage;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/Navbar.css";
import ReorderIcon from "@material-ui/icons/Reorder";
import prof from '../assets/prof.jpg';

const MyButton = ({ myName, imageUrl }) => {
  return (
   
      <Link to="/">
         <div className="homeButton">
          <img src={imageUrl} alt={myName} />
 
          <h3 style={{ margin: "0" }}>{myName}</h3>
          </div>
      </Link>
    
  );
};


const ContactButton = () => {
  return (
   

    <div className="contactButton">
      <Link to="/contact"className="contactLink">
         
          Contact
          
      </Link>
    </div>
  );
};


function LameLink(props) {
  return (
    <div className="lameLink">
      <div className="lameLinkBack">
      <a href= {props.url} target="_blank" rel="noreferrer">
        <div className="lameLinkStack">
        <div className="lameLinkText">
          {props.name}
        </div>
        <div className="lameLinkArrow">
          <img src={prof} alt={">"} />
        </div>
        </div>
      </a>
      </div>
    </div>
  );
}


// function Navbar() {
//   const [expandNavbar, setExpandNavbar] = useState(false);

//   const location = useLocation();

//   useEffect(() => {
//     setExpandNavbar(false);
//   }, [location]);

//   return (
//     <div className="navbar" id={expandNavbar ? "open" : "close"}>
//       <div className="toggleButton">
//         <button
//           onClick={() => {
//             setExpandNavbar((prev) => !prev);
//           }}
//         >
//           <ReorderIcon />
//         </button>
//       </div>
//       <div className="links">
//         {/* <Link to="/"> Kyle Cooper </Link> */}
//         <MyButton name={""} imageUrl={"imageUrl"} />
//         <div className="middle">
//           <Link to="/AboutMe"> About Me </Link>
//           <Link to="/Portfolio"> Portfolio </Link>
//           <Link to="/OtherContent"> Other Content </Link>
//         </div>
//         <div className="last">
//         <Link to="/Contact"> Contact </Link>
//         </div>
//       </div>
//     </div>
//   );
// }


function Navbar() {
  const [expandNavbar, setExpandNavbar] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setExpandNavbar(false);
  }, [location]);

  return (
    <div className="bigNav">
    <div className = "navbarStack">


      <div className="navbar" id={expandNavbar ? "open" : "close"}>
        <div className="toggleButton">
          <button
            onClick={() => {
              setExpandNavbar((prev) => !prev);
            }}
          >
            <ReorderIcon />
          </button>
        </div>
        <div className="links">
        <MyButton myName = "Kyle Cooper" imageUrl = {prof}/>

        <div className="dividerBar2">
        
        <div/>

        </div>
          
          <div className="links2">
            {/* <Link to="/AboutMe"> About Me </Link> */}
            <Link to="/portfolio/workexperience"> Portfolio </Link>
            <Link to="/photoGallery"> Photo Gallery </Link>
            {/* <Link to="/OtherContent"> Other Content </Link> */}
          </div>
        
        </div>

        <ContactButton />
      </div>
      <div className="dividerBar">

      </div>


      
    </div>
    </div>
  );
}

export default Navbar;
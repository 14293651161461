import React from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import GithubIcon from "@material-ui/icons/GitHub";
import "../styles/HomePage.css";
import { Link, useLocation } from "react-router-dom";
import prof from '../assets/prof.jpg';
import arrow from '../assets/arrow.png';


function HomePage() {
  return (
    <div className="home">
       <div className="giantImage">
          <img src={prof} alt={">"} />
        </div>
        <h1> Hi, my name is Kyle Cooper!</h1>
        
        
        
        <p className="prompt">Welcome to my website. I hope to continually add more content, but for now, feel free to check out my portfolio or reach out through my socials below!</p>
        <div className="homeStack">
        <div className="homeStack">
        <LameLink url = "" name = "Portfolio"/>
        </div>
        <div className="icons">
          <a href="https://www.linkedin.com/in/kyle-cooper-07536b230/" target="_blank" rel="noopener noreferrer">
          <LinkedInIcon />
          </a>
          <a href="mailto:kyle.cooper7@outlook.com">
          <EmailIcon />
          </a>
          <a href="https://github.com/kylecooper7" target="_blank" rel="noopener noreferrer">
          <GithubIcon />
          </a>
        </div>
        </div>
    </div>
  // <div className="home">
  //   <h1> Hi, My Name is Kyle Cooper</h1>
  //   <h1> Hi, My Name is Kyle Cooper</h1>
  // </div>


  );
}
function LameLink(props) {
  return (
    <div className="lameLink">
      <div className="lameLinkBack">
      <Link to={`/portfolio/workexperience`}> 
      
        <div className="lameLinkStack">
        <div className="lameLinkText">
          {props.name}
        </div>
        <div className="lameLinkArrow">
          <img src={arrow} alt={">"} />
        </div>
        </div>
        </Link>
      </div>
    </div>
  );
}


export default HomePage;

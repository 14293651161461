import React, {Component} from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import GithubIcon from "@material-ui/icons/GitHub";
import SchoolIcon from '@material-ui/icons/School';
import "../styles/PortfolioPage.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import ScrollSpy from "react-scrollspy-navigation";
import FancyButton from "./FancyButton";
import Section from "./Section";
import SimpleImageSlider from "react-simple-image-slider";
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import NavigationSlider from './NavigationSlider';
import ProjectsGallery from './ProjectsGallery';



import arrow from '../assets/arrow.png';
import prof from '../assets/prof.jpg';

import soumyaProf from '../assets/profs/soumyaProf.jpeg';
import sunnyProf from '../assets/profs/sunnyProf.jpeg';
import marinaProf from '../assets/profs/marinaProf.jpeg';
import payamProf from '../assets/profs/payamProf.jpeg';
import jennaProf from '../assets/profs/jennaProf.jpeg';
import jonathanProf from '../assets/profs/jonathanProf.jpeg';
import artProf from '../assets/profs/artProf.jpeg';
import elyseProf from '../assets/profs/elyseProf.png';
import adamProf from '../assets/profs/adamProf.jpeg';
import schiaviProf from '../assets/profs/schiaviProf.jpeg';
import millerProf from '../assets/profs/millerProf.jpeg';
import mirandaProf from '../assets/profs/mirandaProf.jpeg';



// Imports for vectorcam
import vectorcam1 from '../assets/vectorcam/vectorcam1.jpeg';
import vectorcam2 from '../assets/vectorcam/vectorcam2.jpeg';
import vectorcam3 from '../assets/vectorcam/vectorcam3.jpeg';
import vectorcam4 from '../assets/vectorcam/vectorcam4.jpeg';

// Imports for maputo
import maputo1 from '../assets/maputo/maputo1.jpeg';
import maputo2 from '../assets/maputo/maputo2.jpeg';
import maputo3 from '../assets/maputo/maputo3.jpeg';
import maputo4 from '../assets/maputo/maputo4.jpeg';
import maputo5 from '../assets/maputo/maputo5.jpeg';

// Imports for quilemane
import quilemane1 from '../assets/quilemane/quilemane1.jpeg';
import quilemane2 from '../assets/quilemane/quilemane2.jpeg';
import quilemane3 from '../assets/quilemane/quilemane3.jpeg';
import quilemane4 from '../assets/quilemane/quilemane4.jpeg';

// Imports for field
import field1 from '../assets/field/field1.jpeg';
import field2 from '../assets/field/field2.jpeg';
import field3 from '../assets/field/field3.jpeg';
import field4 from '../assets/field/field4.jpeg';
import field5 from '../assets/field/field5.jpeg';

// Imports for quilemaneMaputo
import quilemaneMaputo1 from '../assets/quilemaneMaputo/quilemaneMaputo1.jpeg';
import quilemaneMaputo2 from '../assets/quilemaneMaputo/quilemaneMaputo2.jpeg';
import quilemaneMaputo3 from '../assets/quilemaneMaputo/quilemaneMaputo3.jpeg';
import quilemaneMaputo4 from '../assets/quilemaneMaputo/quilemaneMaputo4.jpeg';

// Imports for esurgi
import esurgi1 from '../assets/esurgi/esurgi1.jpeg';
import esurgi2 from '../assets/esurgi/esurgi2.jpeg';
import esurgi3 from '../assets/esurgi/esurgi3.jpeg';
import esurgi4 from '../assets/esurgi/esurgi4.jpeg';
import esurgi5 from '../assets/esurgi/esurgi5.jpeg';

// Imports for research
import research1 from '../assets/research/research1.jpeg';
import research2 from '../assets/research/research2.jpeg';

// Imports for securisyn
import securisyn1 from '../assets/securisyn/securisyn1.jpeg';
import securisyn2 from '../assets/securisyn/securisyn2.jpeg';

// Imports for epipocket
import epipocket1 from '../assets/epipocket/epipocket1.jpeg';
import epipocket2 from '../assets/epipocket/epipocket2.jpeg';
import epipocket3 from '../assets/epipocket/epipocket3.jpeg';
import epipocket4 from '../assets/epipocket/epipocket4.jpeg';

// Imports for hackathon
import hackathon1 from '../assets/hackathon/hackathon1.jpeg';
import hackathon2 from '../assets/hackathon/hackathon2.jpeg';
import hackathon3 from '../assets/hackathon/hackathon3.jpeg';

// Imports for hackathonScreenshots
import hackathonScreenshots1 from '../assets/hackathonScreenshots/hackathonScreenshots1.jpeg';
import hackathonScreenshots2 from '../assets/hackathonScreenshots/hackathonScreenshots2.jpeg';
import hackathonScreenshots3 from '../assets/hackathonScreenshots/hackathonScreenshots3.jpeg';
import hackathonScreenshots4 from '../assets/hackathonScreenshots/hackathonScreenshots4.jpeg';

// Imports for appdev
import appdev1 from '../assets/appdev/appdev1.jpeg';
import appdev2 from '../assets/appdev/appdev2.jpeg';
import appdev3 from '../assets/appdev/appdev3.jpeg';
import appdev4 from '../assets/appdev/appdev4.jpeg';
import appdev5 from '../assets/appdev/appdev5.jpeg';

// Imports for cad1
import cad11 from '../assets/cad1/cad11.jpeg';
import cad12 from '../assets/cad1/cad12.jpeg';
import cad13 from '../assets/cad1/cad13.jpeg';

// Imports for cad2
import cad21 from '../assets/cad2/cad21.jpeg';
import cad22 from '../assets/cad2/cad22.jpeg';
import cad23 from '../assets/cad2/cad23.jpeg';
import cad24 from '../assets/cad2/cad24.jpeg';
import cad25 from '../assets/cad2/cad25.jpeg';

// Imports for cad3
import cad31 from '../assets/cad3/cad31.jpeg';
import cad32 from '../assets/cad3/cad32.jpeg';
import cad33 from '../assets/cad3/cad33.jpeg';

// Imports for designTeam
import designTeam1 from '../assets/designTeam/designTeam1.jpeg';
import designTeam2 from '../assets/designTeam/designTeam2.jpeg';
import designTeam3 from '../assets/designTeam/designTeam3.jpeg';

// Imports for designTeamShadow
import designTeamShadow1 from '../assets/designTeamShadow/designTeamShadow1.jpeg';
import designTeamShadow2 from '../assets/designTeamShadow/designTeamShadow2.jpeg';
// import designTeamShadow3 from '../assets/designTeamShadow/designTeamShadow3.jpeg';

// Imports for designTeamSimulation
import designTeamSimulation1 from '../assets/designTeamSimulation/designTeamSimulation1.jpeg';
import designTeamSimulation2 from '../assets/designTeamSimulation/designTeamSimulation2.jpeg';


const images1 = [vectorcam1, vectorcam2, vectorcam3, vectorcam4];

const vectorcam = [vectorcam1, vectorcam2, vectorcam3, vectorcam4];
const maputo = [maputo1, maputo2, maputo3, maputo4, maputo5];
const quilemane = [quilemane1, quilemane2, quilemane3, quilemane4];
const field = [field1, field2, field3, field4, field5];
const quilemaneMaputo = [quilemaneMaputo1, quilemaneMaputo2, quilemaneMaputo3, quilemaneMaputo4];

const esurgi = [esurgi1, esurgi2, esurgi3, esurgi4];
const research = [research1, research2];
const securisyn = [securisyn1, securisyn2];

const epipocket = [epipocket1, epipocket2, epipocket3, epipocket4];

const hackathon = [hackathon1, hackathon2, hackathon3];
const hackathonScreenshots = [hackathonScreenshots1, hackathonScreenshots2, hackathonScreenshots3, hackathonScreenshots4];

const appdev = [appdev1, appdev2, appdev3, appdev4, appdev5];

const cad1 = [cad11, cad12, cad13];
const cad2 = [cad21, cad22, cad23, cad24, cad25];
const cad3 = [cad31, cad32, cad33];

const designTeam = [designTeam1, designTeam2, designTeam3];

const designTeamShadow = [designTeamShadow1, designTeamShadow2];

const designTeamSimulation = [designTeamSimulation1, designTeamSimulation2];

const workExperienceProjects = [
  {
    title: "VectorCam",
    description: "This is a brief description of VectorCam.",
    images: images1,
    link: "/portfolio/workexperience/vectorcam"
  },
  {
    title: "Project B",
    description: "This is a brief description of Project B.",
    images: images1, 
    link: "https://example.com/project-b"
  }
];







function NavigationThing() {
  return (
    <nav>
      <ScrollSpy>
        <FancyButton href="#box-1" text="Box 2" ref={React.createRef()} />
        <FancyButton href="#box-2" text="Box 3" ref={React.createRef()} />
        <FancyButton href="#box-3" text="Box 4" ref={React.createRef()} />
      </ScrollSpy>
    </nav>
  );
}






function TextOnly(props) {
  return <div className="textOnly">{props.text}</div>;
}
function BoldTextOnly(props) {
  return <div className="boldTextOnly">{props.text}</div>;
}

function ListTextOnly(props) {
  return <div className="listTextOnly">
    <ol>
      <li>{props.text}</li>
    </ol>
    </div>;
}

function TextLPSR(props) {
  return (
    <div className="TextLPSR">
    <div className="justText">{props.text}</div>
    <div className="psOnly">
      <SimpleImageSlider
        width={400}
        height={300}
        images={props.images}
        showBullets={true}
        showNavs={true}/>
    </div>
    </div>
  );
}

function TitledTextLPSR(props) {
  return (
    <div className="TextLPSR">
    <div className = "titleAndText">
    <div className="justTitle">{props.title}</div>
    <div className="justText">{props.text}</div>
    </div>
    <div className="psOnly">
      <SimpleImageSlider
        width={400}
        height={300}
        images={props.images}
        showBullets={true}
        showNavs={true}/>
    </div>
    </div>
  );
}

function DoubleTextLPSR(props) {
  return (
    <div className="TextLPSR">
    <div className = "titleAndText">
    <div className="justText">{props.text1}</div>
    <div className="justText">{props.text2}</div>
    </div>
    <div className="psOnly">
      <SimpleImageSlider
        width={400}
        height={300}
        images={props.images}
        showBullets={true}
        showNavs={true}/>
    </div>
    </div>
  );
}

function TextRPSL(props) {
  return (
    <div className="TextRPSL">
    <div>
      <SimpleImageSlider
        width={320}
        height={240}
        images={props.images}
        showBullets={true}
        showNavs={true}/>
    </div>
    <div className="justText">{props.text}</div>
    </div>
  );
}

function PS(props) {
  return (
    <div className = "photoslider">
      <SimpleImageSlider
        width={900}
        height={675}
        images={props.images}
        showBullets={true}
        showNavs={true}
      />
    </div>
  );
}

function LameLink(props) {
  return (
    <div className="lameLink">
      <div className="lameLinkBack">
      <a href= {props.url} target="_blank" rel="noreferrer">
        <div className="lameLinkStack">
        <div className="lameLinkText">
          {props.name}
        </div>
        <div className="lameLinkArrow">
          <img src={arrow} alt={">"} />
        </div>
        </div>
      </a>
      </div>
    </div>
  );
}

const scrollToTop = () => {
window.scrollTo({top:0, behavior: 'smooth'});
}


function NextPageLink(props) {
  return (
    <div className="NextPageLink">
      
      {/* <div className="lameLinkBack"> */}
      <button className="lameLinkBack" onClick={scrollToTop}>
      <Link to={props.link}> 
        <div className="lameLinkStack">
        <div className="lameLinkText">
          {props.name}
        </div>
        <div className="lameLinkArrow">
          <img src={arrow} alt={">"} />
        </div>
        </div>
        </Link>
        </button>
      {/* </div> */}
      
    </div> 
  );
}

function StylishLink(props) {
  return (
    <div>
      <LinkPreview url= {props.url} width='400px' />
    </div>
  );
}

function Contact(props) {
  return (
    <div className = "contact">
      <div className = "contactImage">
       <img src={props.image} alt={props.name} />
      </div>
      <div className="contactInfo">
        <div className = "contactName">
          {props.name}
        </div>
        <div className = "contactDescription">
         {props.description}
        </div >
        
      </div>
      <div className="contactSpacer">

      </div>
      { //Check if message failed
        (props.linkedIn !== undefined)
          ? <div className="contactIcon">
          <a href={props.linkedIn} target="_blank" rel="noopener noreferrer">
          <LinkedInIcon />
          </a>
      </div>
          : <div> </div>
      }
      {/* { //Check if message failed
        (props.school !== null)
          ? <div className="contactIcon">
          <a href={props.school} target="_blank" rel="noopener noreferrer">
          <SchoolIcon />
          </a>
      </div>
          : <div> </div>
      } */}
      
    </div>
  );
}


// function PDFViewer(props) {
//   const [numPages, setNumPages] = useState<number>(1);
//   const [pageNumber, setPageNumber] = useState<number>(1);

//   function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
//     setNumPages(numPages);
//   }

//   return (
//     <div>
//       <Document file={props.url} onLoadSuccess={onDocumentLoadSuccess}>
//         <Page pageNumber={pageNumber} />
//       </Document>
//       <p>
//         Page {pageNumber} of {numPages}
//       </p>
//     </div>
//   );
// }

function Title(props) {
      return <div className="portfolioTitle">{props.text}</div>;
}

function Subtitle(props) {
  return <div className="portfolioSubtitle">{props.text}</div>;
}

function Subsubtitle(props) {
  return <div className="portfolioSubtitle2">{props.text}</div>;
}








function WorkExperienceContent() {




  function WorkExperienceGallery(){
    
    // Add more projects as needed
    return (



      <>


      <Routes>
          <Route path='/' element = {<ProjectsGallery projects={workExperienceProjects} />} >
          </Route>
          
          <Route path='/workexperience/vectorcam' element = {<VectorCam/>} >
          </Route>
          
          <Route path={'/esurgi'} element = {<Esurgi />} >
          </Route>
          
          <Route path={`/research`} element = {<CourseworkContent />} > 
          </Route>
          
          <Route path={`/securisyn`} element = {<Securisyn />} > 
          </Route>

      </Routes>
        
      </>
    )

  }



  function VectorCam(){


    
    function VectorCamDeviceandRole(){
      return (
        <>
        <TextLPSR images = {vectorcam} text = {"In efforts to manage vector-borne diseases such as Malaria, specialized entomologists traditionally analyze mosquitoes to understand their species, sex, and abdomen status. This data plays a vital role in predicting potential outbreaks and directing resources effectively. However, many lower-income countries, which are heavily impacted by these diseases, lack the required expertise for comprehensive surveillance. VectorCam is a device designed to address this gap. This phone attachment, powered by AI, enables standard smartphones to identify mosquitoes without the need for a specialized entomologist. By placing a mosquito in a tray and inserting it into the device, VectorCam's dedicated app uses computer vision to determine the mosquito's key attributes."}/>
        <BoldTextOnly text = "In my involvement with VectorCam, my contributions were:"/>
        <ListTextOnly text = "Device Development: I was responsible for the design, manufacturing, and validation of several iterations of the VectorCam device. Using Fusion 360 for CAD modeling, I worked to ensure the device was efficient and cost-effective."/>
        <ListTextOnly text = "Field Work in Mozambique: I traveled to Mozambique to deliver VectorCam devices, train local entomologists, and set up a pilot study on behalf of the project team."/>
        <ListTextOnly text = "Stakeholder Engagement: I had the opportunity to present the device to Baltazar Candrinho, the Director of the National Malaria Control Program in Mozambique, showcasing its potential and significance."/>
        <ListTextOnly text = "Upcoming Redesign: I'm currently leading a redesign of VectorCam, aiming to release the newest version this spring."/>
        {/* <TextOnly text = "The development and deployment of VectorCam represent the intersection of technology and global health, aiming to bring effective vector surveillance to areas in dire need."/> */}
        </>

        );
    }

    function MozambiqueTrip(){
      return (
        <>
        <TextOnly text = "I was incredibly lucky that I got the opportunity to travel all the way to Mozambique, where I was able to see firsthand how the device I was designing would function in the field. I love to travel, so the chance to see a whole new continent while having so many valuable experiences was a dream come true. The primary purpose of our trip to Mozambique was to deliver the devices I had designed and manufactured, as well as initiate a new pilot study that was going to be performed over the subsequent 3 months. This included meeting, training and presenting to a lot of different people in a lot of different places."/>
        <TitledTextLPSR images = {maputo} title = "Maputo" text = {"Shortly after we landed in Maputo, we met Mercy and Alhagie, two incredible individuals working in conjunction with Mozambique’s malaria control program. I’m not exaggerating when I say they are some of the funniest, most genuine people I’ve ever met. We went on to spend the majority of our trip with them. After they showed us around Maputo, we presented VectorCam to a large group of entomologists at one of the malaria control program’s facilities, and then spent a few hours training them how to use our devices to identify mosquitos This was very valuable for me because I was able to collect a ton of feedback on the device that I could use in future iterations, as well as connect with people who had a completely different perspective than I did."}/>
        
        <TitledTextLPSR images = {quilemane} title = "Quilimane" text = {"We then flew with Mercy and Alhagie to Quilimane, Mozambique’s second most populous city. There, we did a similar presentation and training session with the local entomologists and volunteer coordinators. We ended up having a little bit of time to explore the city, which was beautiful — despite a dodgy experience I had with a rabid dog."}/>
        <TitledTextLPSR images = {field} title = "Field" text = {"From there, we took a 5 hour off-roading car ride inland, to a rural region of Mozambique. We dropped off our bags and immediately began setting up mosquito traps at each of the volunteer locations, staying late into the night. After a few hours of sleep, we were back out in the field, teaching the volunteers how to use the device to image mosquitoes on-site. We got to meet many of the volunteer’s family members, and one of them was even kind enough to show us around their home. This part of the trip was particularly special for me because it was such a unique opportunity to widen my perspective on what it means to live a happy life. "}/>
       
        <TitledTextLPSR images = {quilemaneMaputo} title = "Quilimane & Maputo" text = {"We spent one more night in Quilemane before heading back to Maputo, where we got the chance to tour the main offices of Mozambique’s National Malaria Control Program. There, we presented VectorCam to Baltazar Candrinho, the director of the program. He was very excited to hear about device, and gave us his feedback as well as his contact information so that he could receive updates on our progress. From there, we went straight to the airport for our long flight home. "}/>
        <TextOnly text = "I learned so much on this trip, ranging from specific information pertaining to the field of entomology, to broader-life lessons that I will carry with me forever. I met amazing individuals whose unique backgrounds allowed us to have perspective-altering conversations, and was immersed in a culture that is completely different than I am used to. I am so grateful that I was granted this opportunity."/>
        </>
        );
    }

    function ThesisUpdates(){
      return (
        <>
        <TextOnly text = "My MSE thesis project is to transform the VectorCam device into a product that can be mass produced and used by organizations around the world to perform  widespread vector surveillance and reduce the spread of Malaria and other vector-borne diseases. Currently, the VectorCam device is optimally designed for the year-long usability study that is currently occurrent in Uganda – meaning it is designed for a situation where the phone model used is consistent, the imaging environment is very standardized and users are trained by members of our team. Redesigning the VectorCam device for broader public use will require me to create a device that is phone-agnostic, more durable, more user-friendly and easily manufactured. I plan to incorporate user feedback throughout the design process in order to create a robust, thoughtful product. I will use this page to post update on the progress I’ve made on my thesis so far. "/>
        <BoldTextOnly text = "Updates:"/>
        <ListTextOnly text = "Developed user needs table – must-haves, nice-to-haves, won’t-haves."/>
        <ListTextOnly text = "Drew up device concepts, got feedback from team members and PI."/>
        <ListTextOnly text = "Settled on one device concept – but there’s still multiple components that can be designed in different ways."/>
        <ListTextOnly text = "Split concept into individual mechanisms, designed prototypes in CAD, 3D printed and tested. "/>
        <ListTextOnly text = "Put components together into initial prototype, which was then iterated on to optimize tolerances."/>
        <ListTextOnly text = "Sent prototypes to the field in Uganda, where my team members performed a small-scale usability study and collected useful feedback."/>
        <ListTextOnly text = "Qualitative feedback was compiled into a feedback overview, containing primary points of concern and suggested changes."/>
        <ListTextOnly text = "Currently redesigning for a larger scale usability study in early March. "/>

        </>
        );
    }

    function VectorCamContacts(){
      return (
        <>
        <Contact image = {soumyaProf} name = "Soumya Acharya, MD, MSE, PhD" description = "Graduate Program Director at CBID" linkedIn = "https://www.linkedin.com/in/soumyadipta-acharya-4b6a835b/"/>
        <Contact image = {sunnyProf} name = "Sunny Patel" description = "VectorCam Project Lead" linkedIn = "https://www.linkedin.com/in/smpatel062/"/>
        <Contact image = {marinaProf} name = "Marina Rincon Torroella" description = "Head of Operations" linkedIn = "https://www.linkedin.com/in/marinarincontorroella/"/>
        </>
        );
    }



    return (
    <>
    <Subtitle text = {"VectorCam"} />
    <Subsubtitle text = {"Summary:"} />
      <VectorCamDeviceandRole/>
    <Subsubtitle text = {"Mozambique Trip:"} />
      <MozambiqueTrip/>
    <Subsubtitle text = {"Thesis Updates:"} />
      <ThesisUpdates/>
    <Subsubtitle text = {"Contacts:"} />
      <VectorCamContacts/>
    </>
    );
  }

  function Esurgi(){


    function EsurgiDeviceandRole(){
      return (
        <>
        <TextOnly text = "During the summer of 2022, I undertook an internship as a Biomedical Engineering Intern at Esurgi, located in Baltimore, MD. The majority of my work throughout the summer was focused on advancing their new medical device, the Biostabilizer®: a pressure sensing mat that utilizes machine learning algorithms to assist physical therapists in diagnosing patients’ anatomical issues."/>
        <TextOnly text = "The Biostabilizer® project consists of 3 main divisions: physical hardware, electronics, and software. I was entrusted with the development of a Firebase-backed iOS application that was intended to be used by the physical therapist in conjunction with the physical device, extrapolating useful insights from the raw data. As the only team member with any Firebase/iOS specific coding experience, this project was mostly completed independently, which I enjoyed because I had a lot of ownership over the final product. I coordinated weekly with the hardware team to ensure a seamless compatibility between the app and the device, and met often with my supervisor to showcase my progress."/>
        <TextOnly text = "At the culmination of my internship, I had the honor of presenting the final product to the company's CEO, Payam Kamjoo. In addition to this, I compiled a comprehensive guide intended for future engineers, which provides clear instructions on how to enhance the device with new features, ensuring the adaptability of the Biostabilizer® device in the years to come. "/>
        <BoldTextOnly text = "Check out Esurgi here: "/>
        <LameLink url = "https://myesurgi.com/" name = "Esurgi"/>
        </>
        );
    }

    function Screenshots(){
      return (
        <>
        <PS images = {esurgi}/>
        </>
        );
    }


    function EsurgiContacts(){
      return (
        <>
        <Contact image = {payamProf} name = "Payam Kamjoo" description = "CEO of Esurgi" linkedIn = "https://www.linkedin.com/in/innovativefuture/"/>
        <Contact image = {jennaProf} name = "Jenna Marcinko-Fortner" description = "Project Manager" linkedIn = "https://www.linkedin.com/in/jenna-marcinko-fortner/"/>
        </>
        );
    }

    return (
    <>
    <Subtitle text = {"Esurgi"} />
    <Subsubtitle text = {"Summary:"} />
      <EsurgiDeviceandRole/>
    <Subsubtitle text = {"Screenshots: "} />
      <Screenshots/>
    <Subsubtitle text = {"Contacts: "} />
      <EsurgiContacts/>
    </>
    );
  }

  function Research(){


    function ResearchDeviceandRole(){
      return (
        <>
        <TextOnly text= "In the spring of 2022, I served as a CAD Designer for a research team out of the Johns Hopkins Whiting School of Engineering in Baltimore, MD. The team was developing a device that would attach to a patient’s oxygen concentrator in order to monitor air flow. Then, this data is passed to a machine learning algorithm that is trained to predict oncoming strokes and heart attacks, so that doctors can be notified and preventative measures can be taken before it’s too late. The team brought me on board to create initial designs for the housing of the device. Over the course of the semester, I utilized Fusion 360 to develop CAD models of the casing mechanisms, collaborating with engineers weekly in order to refine the models and implement suggestions. After many CAD iterations, my designs were ultimately used to create one of the team’s first physical prototypes."/>
        </>
        );
    }

    function Screenshots(){
      return (
        <>
          <PS images = {research}/>
        </>
        );
    }


    function ResearchContacts(){
      return (
        <>
        <Contact image = {jonathanProf} name = "Jonathan Smith" description = "Project Lead" contact = "jsmit347@jhu.edu"/>
        </>
        );
    }

    return (
    <>
    <Subtitle text = {"JHU Research Team"} />
    <Subsubtitle text = {"Summary:"} />
      <ResearchDeviceandRole/>
    <Subsubtitle text = {"Screenshots:"} />
      <Screenshots/>
    <Subsubtitle text = {"Contacts:"} />
      <ResearchContacts/>
    </>
    );
  }

  function Securisyn(){


    function SecurisynDeviceandRole(){
      return (
        <>
        <TextLPSR images = {securisyn} text= "In high school, I got the chance to do a couple of summer internships at a local biomedical startup, Securisyn Medical. The company’s mission was to create a product, SolidAIRity®, to prevent unplanned extubation – a disastrous phenomenon where an intubated patient’s breathing tube becomes dislodged, causing them to go without oxygen for often fatal amounts of time. Their product consists of a special intubation tube, and a clip that straps around a patient’s head, securing the tube in place while allowing quick detachment if necessary."/>
        <TextOnly text= "During my time at Securisyn, I took part in weekly design meetings that focused on refining SolidAIRity®. During these sessions, I had the chance to showcase my CAD drawings and propose new ideas to the board of directors. It was very gratifying to see that some of my suggestions were even adopted in the device's final design. I also conducted a pressure study to gauge how well our product worked for different groups of people. To ensure we were on the right track with development, I familiarized myself with FDA guidelines for medical devices and helped fill out the required documents to meet these standards. Overall, this experience filled me with a lot more confidence in my engineering abilities, and I was very lucky that I got to work with individuals who had so expertise to share. "/>
        <BoldTextOnly text = "Check out Securisyn here: "/>
        <LameLink url = "https://www.securisyn.com/" name = "Securisyn"/>
        </>
        );
    }

    function SecurisynLink(){
      return (
        <>
        
         </>
        );
    }


    function SecurisynContacts(){
      return (
        <>
        <Contact image = {artProf} name = "Arthur Kanowitz" description = "Securisyn CEO" linkedIn = "https://www.linkedin.com/in/arthur-kanowitz-md-facep-2233939/"/>
        <Contact image = {elyseProf} name = "Elyse Blazevich" description = "COO & CFO" linkedIn = "https://www.linkedin.com/in/elyse-blazevich-b2a12021/"/>
        </>
        );
    }

    return (
    <>
    <Subtitle text = {"Securisyn"} />
    <Subsubtitle text = {"Summary:"} />
      <SecurisynDeviceandRole/>
    <Subsubtitle text = {"Contacts:"} />
      <SecurisynContacts/>
    </>
    );
  }




  return (
    <>
     <Title text = {"Work Experience"} />
      <VectorCam/>
      <Esurgi/>
      <Research/>
      <Securisyn/>
      <NextPageLink link = "../personalprojects" name = "Personal Projects"/>
     {/* <WorkExperienceGallery/> */}
    </>
   
  );
}

function PersonalProjectsContent() {


  function EpiPocket(){


    function EpiPocketSummary(){
      return (
        <>
        <TextOnly text= "One of my most valuable experiences has been founding and scaling my startup company, EpiPocket. Like 15 million others, I have a food allergy. I’m fatally allergic to nuts and for as long as I can remember, I’ve had to carry an EpiPen and antihistamine with me at all times, in case I get an allergic reaction. The most common and efficient method on the market for carrying these items, until now, was a Ziploc® bag. As a freshman in high school, after years of replacing yellowed, decayed bags, I decided enough was enough — I set out to design my own case to house these items."/>
        <TextLPSR images = {epipocket} text= "Over the next couple of years, I taught myself CAD, continuously refining my designs as I learned new, more advanced techniques. One of the biggest obstacles I faced was that the product I was creating is made of silicone: a material that is notoriously hard to prototype with because it is not 3D printable. I overcame this issue by designing a mold for the case in CAD, 3D printing it, and using it to cast my very first prototypes. I received two design patents for the cases, and performed market research to determine whether or not my product was viable. Today, I am in the process of negotiating a contract with a supplier to mass-produce the cases. As soon as the supply chain is set up, I will be officially deploying the website I have designed to sell the product, opening a listing on Amazon for both of the models and executing the marketing phase of my business plan. "/>
        <TextOnly text= "The reason this experience has been so valuable to me is that it has taught me how to learn in the real world. There’s no textbook or professor for the latest techniques in CAD — it took hundreds of Youtube tutorials, documentation searches, and hours of trial and error to reach the point that I am at. More than anything, this experience gave me the confidence to attack new problems head-on, with the faith that even when I have no idea where to get started, I will figure it out. This confidence in my ability to learn is what drives me to challenge myself and pursue my wildest ambitions. "/>
        <TextOnly text= "Visit my website to learn more – hopefully it’s up and running in time for you to check it out."/>
        <LameLink url = "https://epipocket.com/password" name = "EpiPocket"/>
        {/* <LameLink url = "https://www.instagram.com/epipocket/" name = "Instagram"/> */}
        </>
        );
    }
    function EpiPocketLink(){
      return (
        <>
      
        </>
        );
    }

    function EpiPocketInstagramLink(){
      return (
        <>
      
        </>
        );
    }

    function DesignPatents(){
      return (
        <>
        <TextOnly text = "I currently hold two design patents, one for the EpiPen® case, and one for the AUVI-Q® case. Check them out here: "/>
        <LameLink url = "https://drive.google.com/drive/folders/19kIDMXwuNSp3jCyYO8tCBtju4SHanigr?usp=sharing" name = "Design Patents"/>
        </>
        );
    }

    function EpiPocketContacts(){
      return (
        <>
        <Contact image = {adamProf} name = "Adam Dukes" description = "Manufacturing Contact" contact = "adam@forgejax.com"/>
        </>
        );
    }

    return (
    <>
    <Subtitle text = {"EpiPocket"} />
    <Subsubtitle text = {"Summary:"} />
      <EpiPocketSummary/>
    <Subsubtitle text = {"Design Patents:"} />
      <DesignPatents/>
    <Subsubtitle text = {"Contacts:"} />
      <EpiPocketContacts/>
    </>
    );
  }

  function Hackathon(){


    function HackathonSummary(){
      return (
        <>
        <TextOnly text = "In the spring of 2023, I got the opportunity to compete in Harvard Medical School’s Mission:Brain Global Neurosurgery Hackathon with two of my close friends, Alec Gonzaga and Brian Min. Together, we embarked on a mission to propose a solution for a global neurosurgery problem. We were the only all-undergraduate team to make it to the finalist stage (top 12 teams out of 70), which I consider a remarkable achievement considering that we were competing against  some of the brightest medical students, physicians, and engineers from around the world."/>
        <TextOnly text = "Mission:Brain is a nonprofit founded by a collection of neurosurgeons from all over the world. They put together mission trips in which they identify patients in a LMIC who require neurosurgery and send a team to perform all of the surgeries over a week-long process. At the beginning of the hackathon, they shared an anecdote with us regarding one of their previous patients. To summarize, it was about a young man living in a rural, impoverished community in Mexico, who exhibited all of the early signs of a brain tumor, but was unable to consult a doctor due to temporal and financial restraints. Eventually, after the symptoms were no longer bearable, he sought out help, resulting in a procession of 3 neurosurgeries; but even though the surgeries were successful, he suffered irreversible side effects that have had a devastating impact on his personal life and the life of his family members. Mission:Brain gave us 36 hours to design a solution to any of the problems that occurred in this process before we pitched our idea to a panel of over 40 neurosurgeons. "/>
        <TextLPSR images = {hackathon} text = "Our team composed pages of information detailing our solution, but I will do my best to summarize our proposal: our team identified the core problem from the case study to be that systemic inequities — such as financial, time, and infrastructure constraints — prevent many patients from accessing adequate care at the early stages of their symptoms. In the current inequitable system, a patient’s access to treatment is often based on personal connections. Recognizing the importance of community health workers (CHWs) in connecting with local populations in LMICs, our team developed a 6-month pilot program that begins to bridge the gap between providers and their patients. The project’s aim is to create a formalized network between CHWs, community members, and healthcare providers through the development of an SMS telehealth app, TeleNuro. By partnering with Mexico City’s “Salud en Tu Vida, Salud Para el Bienestar” CHW program, TeleNuro addresses telemedical inequities by equipping CHWs with the proper tools to connect patients with neurosurgical needs to healthcare providers in the Mission:Brain foundation for primary prevention. CHWs are then enabled to distribute the technology required for telemedicine appointments to the patients of their community, allowing patients in LMICs to attend appointments at a fraction of the cost in a much shorter amount of time. This gives them the opportunity to get the help they need from providers before irreversible damage occurs, and Mission:Brain the ability to put together larger, more effective mission trips. "/>
        <TextOnly text = "Working with talented individuals from diverse backgrounds and disciplines was a fantastic experience that taught me how to collaborate effectively under pressure and develop innovative solutions to complex problems. As the only engineer with a coding background on our team, I took on the entirety of the app development, while the public health knowledge that the other members of my team supplied allowed us to brainstorm an abstract, systematic solution to the problem we identified. Because there was so much variation in our skill sets, we were able to leverage each other’s strengths and weaknesses to be extremely effective in designing a solution — a method I hope to continue implementing throughout the rest of my professional career."/>
        <TextOnly text = "Even though we didn’t win the competition and the accompanying funding, we plan to utilize our connections with local CHWs through Johns Hopkins to conduct more research and determine if our solution is viable. If we determine it is — which we highly suspect — we will pursue grants through Johns Hopkins to launch a pilot program in Baltimore city, and eventually in Mexico City, if successful. We believe our solution has the potential to allow patients to overcome the financial, temporal, and infrastructural obstacles that stand between them and the care they need; we plan to take our project as far as it will go."/>
        <TextOnly text = "Competing in this hackathon has reminded me that I flourish in innovative, synergetic, and fast-paced environments. I hope that as my professional career develops, I can continue to surround myself with bright, diverse people in environments like these."/>
        </>
        );
    }

    function Screenshots(){
      return (
        <>
      
        </>
        );
    }


    function HacakthonPictures(){
      return (
        <>
        <PS images = {hackathonScreenshots}/>
        </>
        );
    }

    return (
    <>
    <Subtitle text = {"Mission:Brain Hackathon 2023"} />
    <Subsubtitle text = {"Summary:"} />
      <HackathonSummary/>
    <Subsubtitle text = {"Snapshots from App:"} />
      <HacakthonPictures/>
    </>
    );
  }

  function AppDev(){


    function AppDevSummary(){
      return (
        <>
        <TextOnly text = "Looking back, my journey with computer science began in a comical way — as a teenager into video games. The first project I tackled was to create an automated bot to help me make more of the virtual currency in my favorite game, FIFA. This involved figuring out how to use AppleScript, a scripting language created specifically to interface with Apple products for the purposes of automation. In Fifa, there’s a “transfer market” – you can think of this as the New York Stock Exchange, but instead of stocks, you’re trading players. The prices of the players fluctuate over time, and if you are familiar enough with the market, you can predict the best time is to buy and sell players in order to optimize profits. I made a bot to search the transfer market for players that are listed at a particular price and buy them shortly after they were listed. My brother and I used this bot and strategy to make lots of virtual currency throughout our time playing FIFA. I even added the functionality to text updates to my brother and I when players were purchased. Even though it was silly in hindsight, this is the project that sparked my interest in computer science. If you’re interested, you can check out a video overview I made way back then on Instagram @fifa_sniper_mac."/>
        <TextOnly text = "Since then, my coding capabilities have expanded to include Python, Swift, Java, MATLAB, R, AppleScript, PHP, HTML and CSS, and JavaScript. I have taken on all kinds of projects, like training a neural network to recognize art, developing a Firebase-backed iOS app to pair with a biomedical device, and designing a Chat-GPT-powered automated scheduling system. My personal learning of computer science has been supplemented by the classes I have taken during my time at Johns Hopkins, in which I learn the statistics and mathematical modeling tools necessary to perform meaningful data analysis. Machine Learning, Data Science, Learning Estimation & Control, Computing for Applied Mathematics, and particularly Computational Stem Cell Biology have all given me a strong foundation of knowledge to draw from, and my internship at Esurgi has shown me how to apply these concepts in conjunction with team members. If you want to take a deeper look at a few of my coding projects, I will be uploading them down below. "/>       
        <BoldTextOnly text = "Here's some of the side projects I've worked on:"/>
        <ListTextOnly  text = "Fifa sniper"/>
        <ListTextOnly  text = "Neural network"/>
        <ListTextOnly  text = "Find&Replace"/>
        <ListTextOnly  text = "Business Idea Application"/>
        <ListTextOnly  text = "Ideas"/>
        <ListTextOnly  text = "Workouts"/>
        <ListTextOnly  text = "Langrangian Mechanics Solver / Linearizer"/>

        </>
        );
    }

    function Screenshots(){
      return (
        <>
        <PS images = {appdev}/>
        </>
        );
    }


  

    return (
    <>
    <Subtitle text = {"Application Development"} />
    <Subsubtitle text = {"Summary:"} />
      <AppDevSummary/>
    <Subsubtitle text = {"App Screenshots:"} />
      <Screenshots/>
    </>
    );
  }

  function CadProjects(){


    function CadProjectsSummary(){
      return (
        <>
        <TextLPSR images = {cad1} text = "I started my journey with CAD as a middle schooler, inspired by one of my favorite tv shows, BattleBots. A friend and I were attempting to make a robot in my basement, and I wanted a cooler way to visualize it than just drawing. After some research, I found a CAD program, and downloaded it to my family computer (which definitely contributed to the poor processing speeds my parents frequently complained about). After some experimentation, I found it to be really fun, and I continued to use the software in order to visualize my ideas as I took on different projects. One of my favorites was the custom inlay ping pong table I designed and manufactured with the help of my dad, which is now sitting in our basement."/>
        <TextLPSR images = {cad2} text = "Eventually, at the advice of a mentor from a summer camp I attended, I switched over to Fusion 360 to design EpiPen® and AUVI-Q® silicone carrying cases for my startup venture, EpiPocket. This new software opened up the door to new design techniques I didn’t even know existed, and in order to teach myself, I took on many other side projects, designing anything from a pair of tweezers to a cycloidal drive. "/>
        <TextLPSR images = {cad3} text = "In my junior and senior years of high school, I had my first professional experiences with CAD through an internship at Securisyn Medical, where I helped create new iterations of their product, SolidAIRity®:  a device to prevent the unplanned extubation of intubated patients in emergency situations. Later, In a research position through my sophomore year of college, I designed the casing mechanism for a medical device that analyzes air flow data from patients’ oxygen concentrators to predict and prevent oncoming strokes and heart attacks. Throughout my last year of college, I’ve spent hundreds of hours improving my CAD skills through the design of the VectorCam device. And finally, last semester, I took an upper-level mechanical engineering course in CAD, which gave me a whole new, academic point of view on the full process of design, analysis and simulation. After all of these experiences, I am confident that I am capable of using CAD software to bring to life just about any product that I can conceptualize."/>
        </>
        );
    }

    function Screenshots(){
      return (
        <>
      
        </>
        );
    }


    function CadProjectsContacts(){
      return (
        <>
        
        </>
        );
    }

    return (
    <>
    <Subtitle text = {"CAD Projects"} />
    <Subsubtitle text = {"Summary:"} />
      <CadProjectsSummary/>
    </>
    );
  }

  return (
    <>
     <Title text = {"Personal Projects"} />
      <EpiPocket/>
      <Hackathon/>
      <AppDev/>
      <CadProjects/>
      <NextPageLink link = "../coursework" name = "Coursework"/>
    </>
   
  );


}

function CourseworkContent() {

  function DesignTeam(){


    function DesignTeamSummary(){
      return (
        <>
        <TextOnly text = "The design team curriculum is what makes biomedical engineering at Johns Hopkins so special. Every couple of years, around 20 design teams are started, each with the goal of tackling a medical need. Team members are selected from a competitive pool of applicants, and as a group of five, each team analyzes proposals from potential clinical mentors (typically doctors with some kind of connection to JHU). In their proposals, each potential mentor describes their clinical gap, as well as providing a background about themselves and anybody else they are interested in involving in the project. Each team creates an application to the design proposals they desire, arguing why they are the best fit, and then design team faculty matches each team with a project. "/>
        <TextOnly text = "My team, Design Team 7, was lucky enough to get matched with our first choice proposal, titled “Non-harmful Peripheral Pain Stimulation.”  Over the past six months, with the guidance of our design team course, we have spent a lot of time refining our need statement, addressing our user needs and conducting necessary background research. Here’s a short description of the problem we are trying to solve: "/>
        <TextLPSR images = {designTeam} text = "In the Neuro-ICU, which is full of patients suffering from TBIs (traumatic brain injuries), doctors, nurses and nurse practitioners perform something called a peripheral pain stimulation assessment. In order to monitor the health and progress of their patients over time, clinicians need to know how conscious they are, and one of the ways they analyze that is by seeing how much a patient responds to a painful stimulus on their extremities. Typically, this stimulation is created by the clinician pinching the patient’s skin at varying intensities, or applying firm pressure to their nailbed. Information on how a patient responds to these types of stimuli is important for doctors to make treatment decisions. "/>
        <TextOnly text = "For most patients with some kind of TBI, this assessment occurs every hour. For stroke victims, it occurs every fifteen minutes. The problem is, after so much repetition, virtually all patients suffer from some kind of damage to the skin, ranging from light bruising to more serious medical issues, like artery tears. In the worst cases, this can extend a patient's stay in the ICU, and even in mild cases, patients’ loved ones are often upset about additional injuries being caused, hurting the hospital’s reputation. So, our team’s goal is to come up with a method of causing peripheral pain in a quantifiable way that does not damage the skin over time. "/>
        <BoldTextOnly text = "Here’s a video we created that goes a little more into depth: "/>
        <LameLink url = "https://drive.google.com/file/d/1ilZC8Y44L2peCzxjA20ycktECZWr1tem/view?usp=sharing" name = "Design Team Video"/>
        <TextLPSR images = {designTeamShadow} text = "Through my design team, I’ve gotten the opportunity to connect with a lot of interesting people and have many unique research experiences. For example, recently, we spent the whole day downtown at the Neuro-ICU shadowing with our primary clinical sponsor, Dr. Adam Schiavi, with the goal of developing a deeper understanding of the problem and how our solution could be implemented. We interviewed around 20 different people, a mix of doctors, nurses and nurse practitioners, in order to obtain more information. We then collected data with an Arduino-powered force sensor we created to get a grasp of the variation in pinching intensities, finding initial evidence that there is in fact a huge lack of consistency in pain stimulation, which was promising as far as the importance of our design. Overall the experience was a huge success, providing valuable insights for our design process as well as allowing our team to form valuable connections to be utilized down the road."/>
        <DoubleTextLPSR images = {designTeamSimulation} text1 = "In another experience, we got to tour the Johns Hopkins Simulation Center, a state of the art medical simulation center accessible to us through our other clinical sponsor, Dr. Geoffrey Miller. We got a very informative tour, introductions to a few employees, and even got to take home a skin sample and simulation arm! " text2 = "Now that we have performed a good base of the background research, our team plans to invest the majority of our time through the upcoming five months into prototyping. We are committed to developing a real, functional proof of concept by June. We have the full support of the biomedical engineering department and design studio at Johns Hopkins, as well as a generous amount of funding to get started. Our team has agreed that we plan to take this design as far as we can, including pursuing a patent, developing a proof of concept and getting our product on the market."/>
        <BoldTextOnly text= "Side note:"/>
        <TextOnly text = "I am aware that I have described our problem without describing how we actually plan to solve it, but unfortunately, I won’t be able to share more until we secure IP. I do believe the solution concept we have developed is very promising – for now you’ll just have to take my word for it. I’ll share more as soon as I can!"/>

        </>
        );
    }

    function Screenshots(){
      return (
        <>
      
        </>
        );
    }


    function DesignTeamContacts(){
      return (
        <>
        <Contact image = {schiaviProf} name = "Adam Schiavi, MD, PhD, MS " description = "Neuro-intensivist at JHU Neuro-ICU (Clinical Mentor)" linkedIn = "https://www.linkedin.com/in/adam-schiavi-bb51aa38/"/>
        <Contact image = {millerProf} name = "Geoffrey Miller, PhD" description = "Director of Simulation at Johns Hopkins Medicine" linkedIn = "https://www.linkedin.com/in/geoffrey-miller-46a5046/"/>
        <Contact image = {mirandaProf} name = "Constanza Miranda, PhD" description = "Faculty Mentor" linkedIn = "https://www.linkedin.com/in/constanza-miranda-phd/"/>
        </>
        );
    }

    return (
    <>
    <Subtitle text = {"Design Team"} />
    <Subsubtitle text = {"Project Overview:"} />
      <DesignTeamSummary/>
   <Subsubtitle text = {"Contacts:"} />
      <DesignTeamContacts/>
    </>
    );
  }


  return (
    <>
     <Title text = {"Coursework"} />
      <DesignTeam/>
    </>
   
  );
}



function PortfolioPage() {
  const links = [
    { text: 'Work Experience', path: '/portfolio/workexperience' },
    { text: 'Personal Projects', path: '/portfolio/personalprojects' },
    { text: 'Coursework', path: '/portfolio/coursework' },
  ];
  const tabWidth = '20px';

  return (
    <div className="portfolio">
      <div className="portfolio-topbar">
        {/* <ul>
          <li><Link to={`workexperience`}>Work Experience</Link></li>
          <workexperienceRouteSC/>
          <li><Link to={`personalprojects`}>Personal Projects</Link></li>
          <personalprojectsRouteSC/>
          <li><Link to={`coursework`}>Coursework</Link></li>
          <courseworkRouteSC/>
        </ul> */}
        <NavigationSlider links={links} width={tabWidth} />
        
      </div>
      <div className="portfolio-content">
        <Routes>
          <Route path='/' element = {<WorkExperienceContent/>} >
          </Route>
          
          <Route path='/workexperience' element = {<WorkExperienceContent/>} >
          </Route>
          
          <Route path={'/personalprojects'} element = {<PersonalProjectsContent />} >
          </Route>
          
          <Route path={`/coursework`} element = {<CourseworkContent />} >
            {/* <AcademicsContent /> */}
            
          </Route>
          
          </Routes>
      </div>
    </div>
  );
}


export default PortfolioPage;
